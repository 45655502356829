<template>
  <v-container>
    <v-breadcrumbs
      class="py-2"
      :items="[
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'Dashboard' },
        },
        {
          text: `Liste des types de déchets`,
          disabled: false,
          exact: true,
          to: { name: 'WasteTypes' },
        },
        {
          text: `Type de déchet`,
          disabled: true,
        },
      ]"
    />
    <v-progress-linear v-if="loading" indeterminate />
    <v-card v-else class="mt-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8" md="9" class="d-flex align-center">
            <v-icon size="4em" class="mr-5">mdi-tag-text</v-icon>
            <div class="py-3">
              <div class="text-h4 font-weight-regular mb-2">
                {{ wasteType.name }}
              </div>
              <div class="text-h6 font-weight-light">
                {{ wasteType.question }}
              </div>
              <div class="mt-3">
                <v-icon
                  style="opacity: 0.8; font-size: 1.2em"
                  class="text-small mr-1 ml-1"
                  >mdi-weight</v-icon
                >
                Masse maximale d'une benne :
                {{ wasteType.maximumContainerMass }} kg
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn
              block
              color="info"
              text
              @click="$refs.editForm.open(wasteType)"
            >
              <v-icon left>mdi-pencil</v-icon>
              Modifier
            </v-btn>
            <v-btn
              block
              class="mt-2"
              color="error"
              text
              @click="$refs.removeForm.open(wasteType)"
            >
              <v-icon left>mdi-delete</v-icon>
              Supprimer
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <ListTheoreticalWastes :waste-type="wasteType" is-linked-to-waste-type />
    </v-card>
    <EditWasteType ref="editForm" @finished="fetchData" />
    <RemoveItem
      resource="wasteTypes"
      title="ce type de déchets"
      ref="removeForm"
      @finished="$router.push({ name: 'WasteTypes' })"
    />
  </v-container>
</template>

<script>
import EditWasteType from "../../components/WasteTypes/EditWasteType.vue";
import RemoveItem from "../../components/RemoveItem.vue";
import ListTheoreticalWastes from "@/components/TheoreticalWastes/ListTheoreticalWastes";
export default {
  components: { ListTheoreticalWastes, EditWasteType, RemoveItem },
  data: () => ({
    activeTab: "",
    wasteType: {},
    loading: true,
  }),
  mounted() {
    this.fetchData();
    this.refreshTab();
  },
  watch: {
    "$route.path"() {
      this.refreshTab();
    },
  },
  methods: {
    refreshTab() {
      this.activeTab = this.$route.path;
    },
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch("wasteTypes/fetchOne", this.$route.params.id)
        .then((res) => {
          this.loading = false;
          this.wasteType = res;
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("alert/add", {
            type: "error",
            text: "Impossible de récupérer les informations du type de déchets",
          });
        });
    },
  },
};
</script>
