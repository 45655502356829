<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="wastes"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      @dblclick:row="openItem"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-spacer />
          <v-btn
            outlined
            small
            icon
            dark
            color="primary"
            class="mb-2 mr-4"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            dark
            color="accent"
            class="mb-2"
            @click="$refs.editForm.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            {{
              $vuetify.breakpoint.smAndDown
                ? "Ajouter"
                : "Ajouter un déchet théorique"
            }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small color="info">
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small color="error">
              <v-icon
                small
                @click="$refs.removeForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditTheoreticalWaste
      ref="editForm"
      :default-type="wasteType"
      @finished="fetchData"
    />
    <RemoveItem
      resource="theoreticalWastes"
      title="ce déchet théorique"
      ref="removeForm"
      @finished="fetchData"
    />
  </div>
</template>

<script>
import EditTheoreticalWaste from "./EditTheoreticalWaste.vue";
import RemoveItem from "../RemoveItem.vue";
export default {
  components: { EditTheoreticalWaste, RemoveItem },
  props: {
    // to filter the list of sites by the type they belong to
    wasteType: {
      type: Object,
      default: null,
    },
    isLinkedToWasteType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableLoading: false,
      wastes: [],
      options: {},
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode
          ? [
              {
                text: "Id",
                value: "id",
              },
            ]
          : []),
        {
          text: "Nom",
          value: "name",
          width: "17%",
        },
        ...this.$store.state.elevators.payload.map((el, i) => {
          return {
            text: `${el}kg`,
            value: `weight[${el}]`,
            width: "8%",
          };
        }),
        ...(this.wasteType
          ? []
          : [
              {
                text: "Type de déchet",
                value: "type.name",
              },
            ]),
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.isLinkedToWasteType && !this.wasteType.id) {
          return;
        }
        this.fetchData();
      },
      deep: true,
    },
    wasteType: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    fetchData() {
      let additionalParams = {};
      if (this.wasteType) {
        additionalParams.type = this.wasteType.id;
      }
      this.tableLoading = true;
      this.$store
        .dispatch("theoreticalWastes/fetchAll", {
          ...this.options,
          additionalParams,
        })
        .then((res) => {
          this.tableLoading = false;
          this.wastes = res.member;
          this.wastes.forEach((element, index) => {
            element.weight = {};
            element.elevatorWeight.forEach((el, i) => {
              element.weight[el.elevatorWeight] = el.wasteMass;
            });
          });
          this.totalCount = res.totalItems;
        });
    },
    openItem(e, { item }) {
      this.$refs.editForm.open(item);
    },
  },
};
</script>
