var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-breadcrumbs',{staticClass:"py-2",attrs:{"items":[
      {
        text: 'Dashboard',
        disabled: false,
        to: { name: 'Dashboard' },
      },
      {
        text: "Liste des types de déchets",
        disabled: false,
        exact: true,
        to: { name: 'WasteTypes' },
      },
      {
        text: "Type de déchet",
        disabled: true,
      } ]}}),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"8","md":"9"}},[_c('v-icon',{staticClass:"mr-5",attrs:{"size":"4em"}},[_vm._v("mdi-tag-text")]),_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"text-h4 font-weight-regular mb-2"},[_vm._v(" "+_vm._s(_vm.wasteType.name)+" ")]),_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.wasteType.question)+" ")]),_c('div',{staticClass:"mt-3"},[_c('v-icon',{staticClass:"text-small mr-1 ml-1",staticStyle:{"opacity":"0.8","font-size":"1.2em"}},[_vm._v("mdi-weight")]),_vm._v(" Masse maximale d'une benne : "+_vm._s(_vm.wasteType.maximumContainerMass)+" kg ")],1)])],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-btn',{attrs:{"block":"","color":"info","text":""},on:{"click":function($event){return _vm.$refs.editForm.open(_vm.wasteType)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Modifier ")],1),_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","color":"error","text":""},on:{"click":function($event){return _vm.$refs.removeForm.open(_vm.wasteType)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Supprimer ")],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-3"},[_c('ListTheoreticalWastes',{attrs:{"waste-type":_vm.wasteType,"is-linked-to-waste-type":""}})],1),_c('EditWasteType',{ref:"editForm",on:{"finished":_vm.fetchData}}),_c('RemoveItem',{ref:"removeForm",attrs:{"resource":"wasteTypes","title":"ce type de déchets"},on:{"finished":function($event){return _vm.$router.push({ name: 'WasteTypes' })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }