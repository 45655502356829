<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-text-field
          prepend-icon="mdi-label"
          v-model="wasteType.name"
          required
          type="text"
          label="Nom de la catégorie"
        />

        <v-text-field
          prepend-icon="mdi-help"
          v-model="wasteType.question"
          required
          type="text"
          label="Question (pour l'audit)"
        />

        <v-text-field
          prepend-icon="mdi-weight"
          v-model="wasteType.maximumContainerMass"
          required
          type="number"
          hint="Quelle serait la masse d'une benne standard pleine de ce type de déchet ?"
          label="Masse maximale d'une benne (en kg)"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">Annuler</v-btn>
        <v-btn
          color="success" text
          :loading="loading"
          @click="save">
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rfdc from 'rfdc'

export default {
  computed: {
    formTitle() {
      return this.mode === 'create' ? 'Nouvelle catégorie' : "Modification d'une catégorie"
    }
  },
  data: () => ({
    mode: 'create',
    loading: false,
    dialog: false,
    wasteType: {
      name: '',
      question: '',
      maximumContainerMass: 0
    }
  }),
  methods: {
    open(item = null) {
      let oldMode = this.mode
      this.mode = item ? 'edit' : 'create'
      if (oldMode === 'edit' && this.mode === 'create') {
        this.clearForm()
      }
      if (this.mode === 'edit') {
        this.wasteType = rfdc()(item)
      }
      this.dialog = true
    },
    clearForm() {
      this.wasteType = {
        name: '',
        question: '',
        maximumContainerMass: 0
      }
    },
    close() {
      this.dialog = false
    },
    save() {
      this.loading = true
      let wasteType = Object.assign({}, this.wasteType)
      wasteType.maximumContainerMass = parseInt(wasteType.maximumContainerMass)
      if (this.mode === 'create') {
        this.$store.dispatch('wasteTypes/create', wasteType).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le type de déchet a été créée avec succès"
          })
          this.close()
          this.$emit('finished')
          this.clearForm()
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la création du type de déchet"
          })
        })
      } else {
        this.$store.dispatch('wasteTypes/update', wasteType).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le type de déchet a été modifiée avec succès"
          })
          this.close()
          this.$emit('finished')
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la modification du type de déchet"
          })
        })
      }
    }
  }
}
</script>
