<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-text-field
          prepend-icon="mdi-label"
          v-model="waste.name"
          label="Nom"
        />
        <v-text-field
          prepend-icon="mdi-weight"
          v-show="!variableMass"
          v-model="waste.weight[$store.state.elevators.payload[0]]"
          label="Poids"
          type="text"
        />
        <div v-show="variableMass" style="padding: 0px 25px 15px">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="payload in $store.state.elevators.payload"
                  :key="payload"
                >
                  <td>{{ payload }}</td>
                  <td>
                    <v-text-field
                      v-model="waste.weight[payload]"
                      :label="`Poids pour une cabine de ${payload}`"
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-checkbox
          style="margin: -10px 25px 5px"
          v-model="variableMass"
          label="Poids varie en fonction de la capacité de la cabine"
        />
        <v-alert color="info">
          Dans la formule, vous pouvez utiliser la variable 'niveaux' <br />
          Exemple : 50 * niveaux
        </v-alert>
        <v-text-field
          v-model="waste.type.id"
          prepend-icon="mdi-tag-text"
          label="Id du type"
          :disabled="waste.type != null"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close"> Annuler </v-btn>
        <v-btn color="success" text :loading="loading" @click="save">
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    defaultType: {
      type: Object,
      default: null,
    },
  },
  computed: {
    formTitle() {
      return this.mode === "create"
        ? "Nouveau déchet théorique"
        : "Modification d'un déchet théorique";
    },
  },
  data: () => ({
    mode: "create",
    loading: false,
    dialog: false,
    variableMass: false,
    waste: {
      type: {
        id: null,
      },
      weight: {},
    },
  }),
  methods: {
    clearForm() {
      this.waste = {
        name: "",
        weight: {},
        type: {
          id: "",
        },
      };
    },
    open(item = null, wasteType = null) {
      let oldMode = this.mode;
      this.mode = item ? "edit" : "create";
      if (oldMode === "edit" && this.mode === "create") {
        this.clearForm();
      }
      if (this.mode === "edit") {
        console.log("Item ; ", item);
        this.waste = item;
        this.waste.weight = {};
        item.elevatorWeight.forEach((el, i) => {
          this.waste.weight[el.elevatorWeight] = el.wasteMass;
        });
        this.waste.weight[this.$store.state.elevators.payload[1]] &&
          (this.variableMass = true);
      }
      if (this.defaultType) {
        this.waste.type = this.defaultType;
      } else if (wasteType) {
        this.waste.type = wasteType;
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.loading = true;
      let waste = Object.assign({}, this.waste);
      if (waste.type.id === null) {
        waste.type = null;
      }
      if (waste.type.id) {
        waste.type = "/api/waste_types/" + waste.type.id;
      }
      if (waste.weight && !this.variableMass) {
        const payload = this.$store.state.elevators.payload;
        payload.forEach((el) => {
          waste.weight[el] = waste.weight[payload[0]];
        });
      }
      if (waste.weight) {
        waste.elevatorWeight = [];
        for (const [key, value] of Object.entries(waste.weight)) {
          waste.elevatorWeight.push({
            elevatorWeight: parseInt(key),
            wasteMass: value,
          });
        }
      }

      if (this.mode === "create") {
        this.$store
          .dispatch("theoreticalWastes/create", waste)
          .then(() => {
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "success",
              text: "Le déchet théorique a été créé avec succès",
            });
            this.close();
            this.clearForm();
            this.$emit("finished");
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "error",
              text: "Une erreur est survenue lors de la création du déchet théorique",
            });
          });
      } else {
        this.$store
          .dispatch("theoreticalWastes/update", waste)
          .then(() => {
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "success",
              text: "Le déchet théorique a été modifié avec succès",
            });
            this.close();
            this.$emit("finished");
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "error",
              text: "Une erreur est survenue lors de la modification du déchet théorique",
            });
          });
      }
    },
  },
};
</script>
